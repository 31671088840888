import { usePageContext } from 'application/contexts/PageContext';
import { SocialLinkProps, SocialLinks, SocialLinkType } from 'designsystem/components/3-organisms/SocialLinks';
import { LinkFeature } from 'features/Modules/Link';
import getConfig from 'next/config';
import Head from 'next/head';
import React from 'react';

export const SocialLinksFeature: React.FC<Content.SocialLinks> = ({ content }) => {
	const { linkList } = content?.properties ?? {};

	const pageContext = usePageContext();
	const { publicRuntimeConfig } = getConfig();

	const { host, url, name, pageContent } = pageContext ?? {};
	const { title: seoTitle, description: seoDescription } = pageContent?.page?.properties ?? {};
	const { DOMAIN_PROTOCOL: protocol } = publicRuntimeConfig ?? {};

	let metaUrl: string;
	if (!host?.startsWith('http')) {
		metaUrl = `${protocol ? `${protocol}://` : ''}${host}${url}`;
	}

	const pageTitle = seoTitle ? seoTitle : name;

	const socialLinkItems: SocialLinkProps[] = linkList.map((item) => {
		const { socialLinkType, socialLinkUrl: link } = item.content.properties ?? {};
		const type = socialLinkType.toLowerCase() as SocialLinkType;
		return { type, link: <LinkFeature url={link.url} ariaLabel={link.name} target={link.target} />, linkText: link.name, url: link.url };
	});

	return (
		<>
			<Head>
				{socialLinkItems?.map((item, index) => {
					const socialLinkUrl = linkList[index]?.content?.properties?.socialLinkUrl?.url;
					return item?.type === 'twitter' ? (
						<React.Fragment key={index}>
							<link rel="me" type="text/html" href={socialLinkUrl} />
							<meta property="twitter:url" content={metaUrl} />
							<meta property="twitter:title" content={`${pageTitle} | ${host}`} />
							{seoDescription && <meta property="twitter:description" content={seoDescription} />}
							<meta property="twitter:card" content="summary_large_image" />
						</React.Fragment>
					) : (
						<link key={index} rel="me" type="text/html" href={socialLinkUrl} />
					);
				})}
			</Head>
			<SocialLinks items={socialLinkItems} />
		</>
	);
};
