import classNames from 'classnames';
import { IconText } from 'components/2-molecules/IconText';
import IconFacebook from 'icons/icon_facebook.svg';
import IconInstagram from 'icons/icon_instagram.svg';
import IconLinkedIn from 'icons/icon_linkedin.svg';
import IconTwitter from 'icons/icon_x_twitter.svg';
import IconYouTube from 'icons/icon_youtube.svg';
import { customizeElement } from 'utilities/customizeElement';
import styles from './SocialLinks.module.scss';

export type SocialLinkType = 'facebook' | 'twitter' | 'youtube' | 'instagram' | 'linkedin';
export interface SocialLinkProps {
	type: SocialLinkType;
	linkText: string;
	link: React.ReactElement;
}

export interface SocialLinksProps {
	className?: string;
	items?: SocialLinkProps[];
}

export const SocialLinks: React.FC<SocialLinksProps> = ({ className, items }) => {
	if (!items) {
		return null;
	}

	const renderIcon = (item: SocialLinkProps) => {
		switch (item.type) {
			case 'facebook':
				return <IconFacebook aria-hidden="true" />;
			case 'twitter':
				return <IconTwitter aria-hidden="true" />;
			case 'youtube':
				return <IconYouTube aria-hidden="true" />;
			case 'instagram':
				return <IconInstagram aria-hidden="true" />;
			case 'linkedin':
				return <IconLinkedIn aria-hidden="true" />;
		}
	};

	return (
		<ul className={classNames(styles.SocialLinks, className)}>
			{items.map((item, index) => (
				<li key={index} className={styles.SocialLinks_item}>
					{customizeElement({
						element: item.link,
						style: styles.SocialLinks_link,
						children: (
							<IconText
								key={item.linkText}
								className={styles.SocialLinks_icon}
								iconSize="sm"
								spacing="sm"
								icon={renderIcon(item)}
								text={item.linkText}
							/>
						),
					})}
				</li>
			))}
		</ul>
	);
};
